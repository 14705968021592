<!-- 修改密码 -->
<template>
  <div class="root">
    <el-tabs>
      <el-tab-pane label="修改密码">
        <el-form ref="form" :model="form" :rules="rules" label-width="80px" style="width:300px">
          <el-form-item label="原密码" prop="oldpwd">
            <el-input v-model="form.oldpwd" />
          </el-form-item>
          <el-form-item label="新密码" prop="changepwd">
            <el-input v-model="form.changepwd" />
          </el-form-item>
          <el-form-item label="确认密码" prop="confirmpwd">
            <el-input v-model="form.confirmpwd" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">提交</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>

    </el-tabs>

  </div>
</template>

<script>
import { updatepwd } from '@/api/config'
export default {
    data() {
        return {
            form: {
                oldpwd: '',
                changepwd: '',
                confirmpwd: ''
            },
            rules: {
                oldpwd: [
                    { required: true, message: '请输入原密码', trigger: 'change' }
                ],
                changepwd: [
                    { required: true, message: '请输入新密码', trigger: 'change' }
                ],
                confirmpwd: [
                    { required: true, message: '请输入确认密码', trigger: 'change' }
                ]
            }
        }
    },
    methods: {
        onSubmit() {
            updatepwd({ ...this.form }).then(res => {
                if (res.code === 0) {
                    this.$refs.form.resetFields()
                }
            })
        }
    }
}
</script>

<style lang='scss' scoped>
</style>
